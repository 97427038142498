const appReducerDefaultState = {
    adminApp: false,
    pagerFullPageContextId: false,
    pagerFullPages: [],
    programmFilterOpen: false,
    microSite: false,
    microSiteFrontPage: "",
    microSiteSettings: {},
    morphedLogo: false,
    position: {
      x: 0,
      y: 0,
    },
    activeCloudDetail: null,
    defaultPageOpen: false,
    mobile: false,
    viewMode: true,
    authorOptions: [],
    currentNodeId: null,
  },
  appReducer = (state = appReducerDefaultState, action) => {
    switch (action.type) {
      case "MICROSITE": {
        return Object.assign({}, state, { microSite: action.microSite });
      }
      case "MICROSITE_FRONTPAGE": {
        return Object.assign({}, state, {
          microSiteFrontPage: action.microSiteFrontPage,
        });
      }
      case "MICROSITE_SETTINGS": {
        return Object.assign({}, state, {
          microSiteSettings: action.microSiteSettings,
        });
      }
      case "PROGRAMM_FILTER_OPEN": {
        return Object.assign({}, state, {
          programmFilterOpen: action.programmFilterOpen,
        });
      }
      case "ADMIN_APP": {
        return Object.assign({}, state, { adminApp: action.adminApp });
      }
      case "MORPHED_LOGO": {
        return Object.assign({}, state, { morphedLogo: action.morphedLogo });
      }
      case "PAGER_FULL_PAGE": {
        // Remove if they are pushed twice (or after query finish).
        const pagerFullPages = state.pagerFullPages.filter(
          (config) => config.id !== action.config.id
        );

        return Object.assign({}, state, {
          pagerFullPages: [...pagerFullPages, action.config],
        });
      }
      case "PAGER_FULL_PAGE_CONTEXT_ID": {
        return Object.assign({}, state, { pagerFullPageContextId: action.id });
      }
      case "RESTORE_POSITION": {
        return Object.assign({}, state, { position: action.position });
      }
      case "MOBILE": {
        return Object.assign({}, state, { mobile: action.mobile });
      }
      case "AUTHOR_OPTIONS": {
        return Object.assign({}, state, {
          authorOptions: action.authorOptions,
        });
      }
      case "VIEW_MODE": {
        return Object.assign({}, state, { viewMode: action.viewMode });
      }
      case "ACTIVE_CLOUD_DETAIL": {
        return Object.assign({}, state, {
          activeCloudDetail: action.activeCloudDetail,
        });
      }
      case "DEFAULT_PAGE_OPEN": {
        return Object.assign({}, state, {
          defaultPageOpenAction: action.defaultPageOpenAction,
        });
      }
      case "CURRENT_NODE_ID": {
        return Object.assign({}, state, {
          currentNodeId: action.currentNodeId,
        });
      }
      default:
        return state;
    }
  };

export { appReducerDefaultState, appReducer };
