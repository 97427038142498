import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useQuery } from "@apollo/client";

import ErrorBoundary from "../../error-boundary";
import ComponentClickSuggestionLink from "../../general-components/content-base/paragraphs/component-click-suggestion-link";
import soundCityConfigQuery from "../../microsites/sound-city/sound-city-config-query.graphql";

const CTATeaser = ({ content }) => {
  return (
    <section
      className="paragraph paragraph-call-to-action"
      style={{ padding: "32px 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-16 d-flex justify-content-center">
            {content.fieldHeading && <h2>{content.fieldHeading}</h2>}

            {content.fieldCallToAction && (
              <ErrorBoundary>
                <ComponentClickSuggestionLink
                  className="btn btn-primary"
                  link={content.fieldCallToAction}
                />
              </ErrorBoundary>
            )}

            {content.fieldText && (
              <div
                style={{ marginTop: "2rem" }}
                className="text"
                dangerouslySetInnerHTML={{
                  __html: content.fieldText.processed,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const InternalSoundCityTeasers = ({ currentUserRoles }) => {
  let { loading, error, data } = useQuery(soundCityConfigQuery);

  if (loading || error) {
    return null;
  }

  if (!data.configPagesById.fieldEnableCtas) {
    return null;
  }
  const content = [
    {
      entityBundle: "call_to_action",
      entityId: "1793",
      fieldCallToAction: {
        title: data.configPagesById.fieldCta1Titel,
        url: {
          __typename: "EntityCanonicalUrl",
          routed: true,
          path: "/intern/sound-city",
        },
      },
      fieldText: {
        value: data.configPagesById.fieldCta1Text?.processed || "",
        processed: data.configPagesById.fieldCta1Text?.processed || "",
      },
      //fieldHeading: "Zum Anmeldeformular für Sound City 2023",
      fieldAlternativeFarbe: false,
      roles: [
        "admin",
        "erweiterte_redaktion",
        "redakteur",
        "interne",
        "sound_city",
      ],
    },
    {
      entityBundle: "call_to_action",
      entityId: "1796",
      fieldCallToAction: {
        title: data.configPagesById.fieldCta2Titel,
        url: {
          routed: false,
          path: "/intern/sound-city/eingaben",
        },
      },
      fieldText: {
        value: data.configPagesById.fieldCta2Text?.processed || "",
        processed: data.configPagesById.fieldCta2Text?.processed || "",
      },
      //fieldHeading: "Meine Eingaben",
      fieldAlternativeFarbe: false,
      roles: [
        "admin",
        "erweiterte_redaktion",
        "redakteur",
        "interne",
        "sound_city",
      ],
    },
    {
      entityBundle: "call_to_action",
      entityId: "1799",
      fieldCallToAction: {
        title: data.configPagesById.fieldCta3Titel,
        url: {
          routed: false,
          path: "/intern/anmeldung-fuer-sound-city",
        },
      },
      fieldText: {
        value: data.configPagesById.fieldCta3Text?.processed || "",
        processed: data.configPagesById.fieldCta3Text?.processed || "",
      },
      //fieldHeading: "Meine Eingaben",
      fieldAlternativeFarbe: false,
      roles: ["admin", "erweiterte_redaktion", "redakteur", "interne"],
    },
  ];

  return (
    <div className="container">
      <div className="row">
        {content.map((item, index) => {
          if (!item.roles.some((role) => currentUserRoles.includes(role))) {
            return false;
          }

          return (
            <div
              className={classNames({
                "col-16 col-md-5": false,
                "offset-md-1": false,
                "col-16": true,
              })}
            >
              <CTATeaser content={item} key={index} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InternalSoundCityTeasers;
