const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  microSiteAction = (microSite) => ({
    microSite,
    type: "MICROSITE",
  }),
  microSiteFrontPageAction = (microSiteFrontPage) => ({
    microSiteFrontPage,
    type: "MICROSITE_FRONTPAGE",
  }),
  microSiteSettingsAction = (microSiteSettings) => ({
    microSiteSettings,
    type: "MICROSITE_SETTINGS",
  }),
  programmFilterOpenAction = (programmFilterOpen) => ({
    programmFilterOpen,
    type: "PROGRAMM_FILTER_OPEN",
  }),
  morphedLogoAction = (morphedLogo) => ({
    morphedLogo,
    type: "MORPHED_LOGO",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  cloudViewRestorePosition = (position) => ({
    position,
    type: "RESTORE_POSITION",
  }),
  mobileAction = (mobile) => ({
    mobile,
    type: "MOBILE",
  }),
  viewModeAction = (viewMode) => ({
    viewMode,
    type: "VIEW_MODE",
  }),
  activeCloudDetailAction = (activeCloudDetail) => ({
    activeCloudDetail,
    type: "ACTIVE_CLOUD_DETAIL",
  }),
  defaultPageOpenAction = (defaultPageOpen) => ({
    defaultPageOpen,
    type: "DEFAULT_PAGE_OPEN",
  }),
  authorOptionsAction = (authorOptions) => ({
    authorOptions,
    type: "AUTHOR_OPTIONS",
  }),
  currentNodeIdAction = (currentNodeId) => ({
    currentNodeId,
    type: "CURRENT_NODE_ID",
  });

export {
  adminAppAction,
  microSiteAction,
  microSiteFrontPageAction,
  microSiteSettingsAction,
  programmFilterOpenAction,
  morphedLogoAction,
  pagerFullPagesAction,
  pagerFullPageContextAction,
  activeCloudDetailAction,
  defaultPageOpenAction,
  cloudViewRestorePosition,
  viewModeAction,
  authorOptionsAction,
  mobileAction,
  currentNodeIdAction,
};
