import React, { Component } from "react";
import PropTypes from "prop-types";

import ParagraphHeroText from "./paragraphs/paragraph-hero-text";
import ParagraphClickSuggestions from "./paragraphs/paragraph-click-suggestions";
import ParagraphTeaserList from "./paragraphs/teaserliste/paragraph-teaser-list";
import ParagraphTeaserImage from "./paragraphs/paragraph-teaser-image";
import ParagraphSectionheading from "./paragraphs/paragraph-sectionheading";
import ParagraphText from "./paragraphs/paragraph-text";
import ParagraphSCTitel from "../../microsites/sound-city/modules/paragraph-sound-city-titel";
import ParagraphProgramm from "../../microsites/sound-city/modules/programm/paragraph-programm";
import ParagraphContentCloudAudio from "../../microsites/content-cloud/modules/audio/paragraph-audio";
import ParagraphContentCloudText from "../../microsites/content-cloud/modules/text/paragraph-text";
import ParagraphBilderSlider from "./paragraphs/image-image-slider/paragraph-bilder-slider";
import ParagraphImageCollection from "./paragraphs/image-collection/paragraph-image-collection";
import ParagraphNumberFacts from "./paragraphs/paragraph-number-facts";
import ParagraphTeaserSlider from "./paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphNewsletteranmeldung from "./paragraphs/paragraph-newsletteranmeldung";
import ParagraphAkkordionText from "./paragraphs/akkordion-text/paragraph-akkordion-text";
import ParagraphDossier from "./paragraphs/paragraph-dossier";
import ParagraphMedia from "./paragraphs/media-video-audio/paragraph-media";
import ParagraphPageTitle from "./paragraphs/paragraph-page-title";
import ParagraphCallToAction from "./paragraphs/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "./paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphCalendarInformation from "./paragraphs/paragraph-calendar-information";
import ParagraphDownload from "./paragraphs/paragraph-download";
import ParagraphZitat from "./paragraphs/paragraph-zitat";
import ParagraphDownloadSchublade from "./paragraphs/paragraph-download-schublade";
import ParagraphTimeline from "./paragraphs/timeline/paragraph-timeline";
import ParagraphKontakt from "./paragraphs/kontakt/paragraph-kontakt";
import ParagraphStrukturierteListe from "./paragraphs/strukturierte-liste/paragraph-strukturierte-liste";
import ParagraphPersonenInformationen from "./paragraphs/personen-informationen/paragraph-personen-informationen";
import ParagraphKalenderEinzelDoppel from "./paragraphs/kalender-einzel-doppel/paragraph-kalender-einzel-doppel";
import ParagraphKalenderAbschnitt from "./paragraphs/kalender-abschnitt/paragraph-kalender-abschnitt";
import ParagraphKalenderMonat from "./paragraphs/kalender-monat/paragraph-kalender-monat";
import ParagraphAudio from "./paragraphs/paragraph-audio";
import ParagraphLogosPartners from "./paragraphs/logos-partners/logos-partners";
import ParagraphTeaserListEvents from "./paragraphs/teaserliste/events/paragraph-teaser-list-events";
import ParagraphFormular from "./paragraphs/webform/paragraph-formular";
import ErrorBoundary from "../../error-boundary";
import ParagraphTeaserListPages from "./paragraphs/teaserliste/pages/paragraph-teaser-list-pages";
import ParagraphKlappmodul from "./paragraphs/klappmodul/paragraph-klappmodul";
import Scene from "./../../microsites/content-cloud/modules/3d/scene";
import ParagraphTextAndSpeech from "./../../microsites/content-cloud/modules/text-and-speech/paragraph-text-and-speech";
import ParagraphIframeEmbed from "./paragraphs/paragraph-iframe-embed";

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.map((item, index) =>
          // eslint-disable-next-line complexity
          (() => {
            if (this.props.currentUserRoles && item.roles) {
              if (
                !item.roles.some((role) =>
                  this.props.currentUserRoles.includes(role)
                )
              ) {
                return null;
              }
            }
            switch (item.entity.entityBundle) {
              case "iframe_embed":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphIframeEmbed content={item.entity} />
                  </ErrorBoundary>
                );
              case "audio":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphAudio content={item.entity} />
                  </ErrorBoundary>
                );
              case "media":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphMedia content={item.entity} />
                  </ErrorBoundary>
                );
              case "hero_text":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphHeroText content={item.entity} />
                  </ErrorBoundary>
                );
              case "_sound_city_titel":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphSCTitel content={item.entity} />
                  </ErrorBoundary>
                );
              case "klappmodul":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKlappmodul
                      content={item.entity}
                      location={this.props.location}
                    />
                  </ErrorBoundary>
                );
              case "click_suggestions":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphClickSuggestions content={item.entity} />
                  </ErrorBoundary>
                );
              case "teaserliste":
              case "teaserliste_news":
              case "teaserliste_personen":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserList content={item.entity} />
                  </ErrorBoundary>
                );
              case "teaserliste_events":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserListEvents content={item.entity} />
                  </ErrorBoundary>
                );
              case "teaserliste_pages":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserListPages content={item.entity} />
                  </ErrorBoundary>
                );
              case "teaser_image_text":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserImage content={item.entity} />
                  </ErrorBoundary>
                );
              case "section_heading":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphSectionheading content={item.entity} />
                  </ErrorBoundary>
                );
              case "text":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphText content={item.entity} />
                  </ErrorBoundary>
                );
              case "bilder_slider":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphBilderSlider content={item.entity} />
                  </ErrorBoundary>
                );
              case "image_collection":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphImageCollection content={item.entity} />
                  </ErrorBoundary>
                );
              case "zahlen_und_fakten":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphNumberFacts content={item.entity} />
                  </ErrorBoundary>
                );
              case "teaser_slider":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserSlider content={item.entity} />
                  </ErrorBoundary>
                );
              case "newsletteranmeldung":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphNewsletteranmeldung content={item.entity} />
                  </ErrorBoundary>
                );
              case "text_akkordion":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphAkkordionText content={item.entity} />
                  </ErrorBoundary>
                );
              case "teaser_sammlung_dossier_":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphDossier content={item.entity} />
                  </ErrorBoundary>
                );
              case "seitentitel":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphPageTitle content={item.entity} />
                  </ErrorBoundary>
                );
              case "call_to_action":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphCallToAction content={item.entity} />
                  </ErrorBoundary>
                );
              case "text_and_speech":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTextAndSpeech content={item.entity} />
                  </ErrorBoundary>
                );
              case "3d":
                return (
                  <ErrorBoundary key={index}>
                    <div className={`module-wrapper`}>
                      <Scene content={item.entity} />
                    </div>
                  </ErrorBoundary>
                );
              case "erweiterte_teaseruebersicht":
              case "teaseruebersicht_events":
              case "teaseruebersicht_projekte":
              case "teaseruebersicht_schulen":
              case "teaseruebersicht_personen": {
                const nodes = {};

                if (item.entity.fieldManuelleSortierung) {
                  nodes.loading = false;
                  nodes.nodeQuery = {};
                  nodes.nodeQuery.entities = item.entity.fieldBeitraege.map(
                    (node) => node.entity
                  );

                  return (
                    <ErrorBoundary key={index}>
                      <ParagraphExtendedTeaserOverview
                        content={item.entity}
                        nodes={nodes}
                      />
                    </ErrorBoundary>
                  );
                }

                return (
                  <ErrorBoundary key={index}>
                    <ParagraphExtendedTeaserOverview content={item.entity} />
                  </ErrorBoundary>
                );
              }
              case "veranstaltungsinformationen":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphCalendarInformation
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "personeninformationen":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphPersonenInformationen
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "download":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphDownload
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "zitat":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphZitat
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "download_schublade":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphDownloadSchublade
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "timeline":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTimeline
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "kontakt":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKontakt
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "strukturierte_liste":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphStrukturierteListe
                      content={item.entity}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "kalender_doppel_einzel_highlight":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKalenderEinzelDoppel content={item.entity} />
                  </ErrorBoundary>
                );
              case "kalenderabschnitt":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKalenderAbschnitt content={item.entity} />
                  </ErrorBoundary>
                );
              case "_sound_city_programm":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphProgramm content={item.entity} />
                  </ErrorBoundary>
                );
              case "_content_cloud_audio":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphContentCloudAudio content={item.entity} />
                  </ErrorBoundary>
                );
              case "text_cc":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphContentCloudText content={item.entity} />
                  </ErrorBoundary>
                );
              case "kalendermonatsuebersicht":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKalenderMonat content={item.entity} />
                  </ErrorBoundary>
                );
              case "logos_partners":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphLogosPartners content={item.entity} />
                  </ErrorBoundary>
                );
              case "formular":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphFormular content={item.entity} />
                  </ErrorBoundary>
                );
              case "from_library": {
                switch (
                  item.entity.fieldReusableParagraph.entity.paragraphs.entity
                    .entityBundle
                ) {
                  case "media":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphMedia
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "hero_text":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphHeroText
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "click_suggestions":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphClickSuggestions
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "teaserliste":
                  case "teaserliste_personen":
                  case "teaserliste_news":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphTeaserList
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "teaserliste_events":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphTeaserListEvents
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "teaserliste_pages":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphTeaserListPages
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "teaser_image_text":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphTeaserImage
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "section_heading":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphSectionheading
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "text":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphText
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "bilder_slider":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphBilderSlider
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "zahlen_und_fakten":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphNumberFacts
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "teaser_slider":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphTeaserSlider
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "newsletteranmeldung":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphNewsletteranmeldung
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "text_akkordion":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphAkkordionText
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "teaser_sammlung_dossier_":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphDossier
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "seitentitel":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphPageTitle
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "call_to_action":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphCallToAction
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "erweiterte_teaseruebersicht":
                  case "teaseruebersicht_events":
                  case "teaseruebersicht_projekte":
                  case "teaseruebersicht_schulen":
                  case "teaseruebersicht_personen": {
                    const nodes = {};

                    if (item.entity.fieldManuelleSortierung) {
                      nodes.loading = false;
                      nodes.nodeQuery = {};
                      nodes.nodeQuery.entities = item.entity.fieldBeitraege.map(
                        (node) => node.entity
                      );

                      return (
                        <ErrorBoundary key={index}>
                          <ParagraphExtendedTeaserOverview
                            content={
                              item.entity.fieldReusableParagraph.entity
                                .paragraphs.entity
                            }
                            nodes={nodes}
                          />
                        </ErrorBoundary>
                      );
                    }

                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphExtendedTeaserOverview
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  }
                  case "download":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphDownload
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "zitat":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphZitat
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "download_schublade":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphDownloadSchublade
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "timeline":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphTimeline
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "kontakt":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphKontakt
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "strukturierte_liste":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphStrukturierteListe
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "kalender_doppel_einzel_highlight":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphKalenderEinzelDoppel
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "kalenderabschnitt":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphKalenderAbschnitt
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "kalendermonatsuebersicht":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphKalenderMonat
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "logos_partners":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphLogosPartners
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "_content_cloud_audio":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphContentCloudAudio
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  case "formular":
                    return (
                      <ErrorBoundary key={index}>
                        <ParagraphFormular
                          content={
                            item.entity.fieldReusableParagraph.entity.paragraphs
                              .entity
                          }
                        />
                      </ErrorBoundary>
                    );
                  default:
                    return null;
                }
              }
              default:
                return null;
            }
          })()
        )}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.array.isRequired,
  className: PropTypes.string,
  nodeContent: PropTypes.object,
  currentUserRoles: PropTypes.array,
};

export default ContentBase;
