import React, { Component } from "react";
import ScReg from "./sc-reg";
import ScBold from "./sc-bold";
import { self } from "../config";
import { connect } from "react-redux";
import { MorphReplace } from "react-svg-morph";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite,
  microSiteSettings: reduxStore.appStore.microSiteSettings,
  morphedLogo: reduxStore.appStore.morphedLogo,
  currentNodeId: reduxStore.appStore.currentNodeId,
});

class LogoButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      morphed: false,
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  render() {
    let logoPath = "";
    switch (this.props.currentNodeId) {
      case "1454":
        logoPath = "Logo_JUMU.svg?t=0";
        break;
      case "1667":
        logoPath = "logo-vdmk-black.svg";
        break;
      default:
        logoPath = "logo-vdmk-red.svg";
        break;
    }

    if (this.props.microSite && this.props.microSiteSettings) {
      logoPath = this.props.microSiteSettings.logo;
    }

    return (
      <a
        className="site-logo-button"
        href="/"
        style={this.props.currentNodeId === "1454" ? { maxWidth: "400px" } : {}}
      >
        {this.props.microSite === "sound-city" ? (
          <>
            {this.props.location.pathname === "/" ||
            this.props.location.pathname === "/page/sound-city-startseite" ? (
              <>
                {this.state.mounted && (
                  <>
                    <MorphReplace
                      width={276}
                      height={168}
                      duration={200}
                      rotation={"none"}
                    >
                      {this.props.morphedLogo ? (
                        <ScReg key={"regular"} />
                      ) : (
                        <ScBold key={"bold"} />
                      )}
                    </MorphReplace>
                    <img
                      className="d-block city"
                      src={`${self}/sound-city/logos/sound-city-city.svg`}
                      alt="Sound City Logo"
                      title="Sound City"
                    />
                  </>
                )}
              </>
            ) : (
              <img
                className="d-block"
                src={`${self}/sound-city/logos/sound-city-regular-basic.svg`}
                alt="Sound City Logo"
                title="Sound City"
              />
            )}
          </>
        ) : (
          <>
            <img
              className="d-block"
              src={`${self}/${logoPath}`}
              alt="VdMK Logo"
              title="VdMK"
              style={
                this.props.currentNodeId === "1454" ? { height: "auto" } : {}
              }
            />
          </>
        )}
      </a>
    );
  }
}

LogoButton.propTypes = {};

export default connect(mapStateToProps)(LogoButton);
