import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import { states } from "../states";
import classNames from "classnames";

const CheckboxField = ({ formik, item }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  const { setFieldValue } = formik;

  return (
    <div
      className={classNames({
        "form-group form-check": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        <Field
          className="form-check-input"
          type="checkbox"
          name={item.id}
          id={item.id}
          onChange={(e) => {
            setFieldValue(item.id, e.target.checked);
          }}
          onBlur={formik.handleBlur}
          required={(!!item.required || required) && !optional && visible}
          disabled={!enabled || disabled}
        />

        <span>{item.title}</span>

        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <ErrorMessage component="span" name={item.id} className="error-message" />

      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

CheckboxField.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default CheckboxField;
