import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import axios from "axios";
import { restHostBackend } from "../../../../../config";
import { states } from "../states";
import classNames from "classnames";
import { useApolloClient } from "@apollo/client";
import fileInfoQuery from "./fileinfo-query.graphql";

/**
 * @typedef {Object} FileInfo
 * @property {string} id - The file ID.
 * @property {number} changed - The file last modified date.
 * @property {number} created - The file creation date.
 * @property {string} filemime - The file MIME type.
 * @property {string} filename - The file name.
 * @property {number} filesize - The file name.
 * @property {bool} status - The file status.
 * @property {object} uri - The file URI.
 * @property {string} uri.value - The raw URI value.
 * @property {string} uri.url - The external URL derived from the URI.
 * @property {string} url - The file URL.
 */

const defaultAllowedExtentions = [
  "csv",
  "jpeg",
  "jpg",
  "pdf",
  "png",
  "svg",
  "txt",
];

/**
 * Get file information from the server.
 *
 * @param {string} fid - The file ID.
 * @param {ApolloClient} apolloClient - The Apollo client instance.
 * @returns {Promise<object|null>} - The file information or null if not found.
 */
const getFileInfo = async (fid, apolloClient) => {
  const res = await apolloClient.query({
    query: fileInfoQuery,
    variables: {
      fid,
    },
  });
  const resInfo = res.data?.fileById;
  if (!resInfo) {
    return null;
  }
  const fileInfoKeys = Object.keys(resInfo);

  let fileInfo = {};
  fileInfoKeys.forEach((key) => (fileInfo[key] = resInfo[key]));

  return fileInfo;
};

const uploadFile = async (file, token, item) => {
  const uploadFileData = {
    name: file.name,
    file: file.file,
    destination: item.destination ? item.destination : null,
  };

  const payload = JSON.stringify(uploadFileData);

  try {
    const response = await axios({
      method: "post",
      url: `${restHostBackend}/api/webforms/createfile`,
      data: payload,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in uploadFile:", error);
    throw error;
  }
};

const convertToBase64 = async (file, token, item) => {
  const reader = new FileReader();

  const readFileAsDataURL = (file) =>
    new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  try {
    const base64File = await readFileAsDataURL(file);
    file.file = base64File;

    const fid = await uploadFile(file, token, item);

    return fid;
  } catch (error) {
    console.error("Error in convertToBase64:", error);
    throw error;
  }
};

const calculateMaxFileSize = (setting = null) => {
  if (setting) {
    setting = parseInt(setting);

    // Setting is in MB, transform to Byte.
    return setting * 1000000;
  }

  // 5 MB in Bytes
  return 5000000;
};

/**
 * Create an array of file info objects from the field value.
 *
 * The returned array will be used to loop over and build markup for each file.
 *
 * @param {string|array} fieldValue - The field value containing file IDs.
 * @param {boolean} isMultiple - Indicates if the field allows multiple files.
 * @param {object} fileInfos - An object containing file information indexed by file ID.
 * @returns {FileInfo[]} - An array of file info objects.
 */
const createFilesArray = (fieldValue, isMultiple, fileInfos) => {
  if (!fieldValue) {
    return [];
  }

  const items = isMultiple ? fieldValue : [fieldValue];

  const files = items.map((fid) => {
    if (
      typeof fileInfos[fid] === "undefined" ||
      typeof fileInfos[fid] !== "object" ||
      fileInfos[fid] === null
    ) {
      return { fid: fid };
    } else {
      return {
        ...fileInfos[fid],
      };
    }
  });

  return files;
};

const FileField = ({ item, token, language }) => {
  const apolloClient = useApolloClient();

  // The values of the entire form.
  const { values, setFieldValue } = useFormikContext();
  /**
   * @var {object<string,FileInfo>} fileInfos
   */
  const [fileInfos, setFileInfos] = useState({});
  const fileInfosRef = useRef(fileInfos);

  const fieldValue = values?.[item.id] || null;
  const isMultiple = !!item.multiple;
  const files = createFilesArray(fieldValue, isMultiple, fileInfos);

  const removeFile = (fileId) => {
    if (isMultiple) {
      const updatedFieldValue = fieldValue.filter(
        (fieldValueItem) => fieldValueItem !== fileId
      );
      setFieldValue(item.id, updatedFieldValue);
    } else {
      setFieldValue(item.id, "");
    }
  };

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  useEffect(() => {
    fileInfosRef.current = fileInfos;
  }, [fileInfos]);

  useEffect(() => {
    if (!fieldValue) {
      return;
    }
    const updateFileInfos = async () => {
      const fileIds =
        typeof fieldValue === "string" ? [fieldValue] : fieldValue;

      const updatedFileInfos = { ...fileInfosRef.current };

      for (const fid of fileIds) {
        if (typeof updatedFileInfos[fid] === "undefined") {
          const fileInfo = await getFileInfo(fid, apolloClient);
          if (fileInfo) {
            updatedFileInfos[fid] = fileInfo;
          }
        }
      }
      setFileInfos((prevFileInfos) => {
        return {
          ...prevFileInfos,
          ...updatedFileInfos,
        };
      });
    };

    updateFileInfos();
  }, [fieldValue, apolloClient]);

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id} className="form-label form-label--filefield">
        {item.title}{" "}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      <input
        id={item.id}
        name={item.id}
        type="file"
        accept="application/pdf, image/*, .csv, text/plain, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="form-control"
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        multiple={isMultiple}
        onChange={async (event) => {
          const allowedExt =
            item.fileExtensions !== ""
              ? item.fileExtensions
              : defaultAllowedExtentions;

          if (event.target.files.length <= 0) {
            return;
          }
          const filesArray = Array.from(event.target.files);
          const uploadedFiles = isMultiple ? [...(fieldValue || [])] : [];
          const uploadedFileInfos = {};

          for (const file of filesArray) {
            const ext = file.name.match(/\.([^.]+)$/)[1];

            if (!allowedExt.includes(ext.toLowerCase())) {
              window.alert(`${file.name} has the wrong format!`);
            }
            // If filesize is bigger than 5mb
            else if (file.size > calculateMaxFileSize(item.max_filesize)) {
              window.alert(
                `${file.name} is to big!
                Max Filesize ${
                  calculateMaxFileSize(item.max_filesize) / 1000000
                }MB !`
              );
            } else {
              // @todo it seems this does not get waited for, so its called parallel which is bad.
              const fid = await convertToBase64(file, token, item);

              uploadedFiles.push(fid);
            }
          }

          // Update the state with the new array of files.
          if (isMultiple) {
            //@todo: currently this works when uploading. But Filelist is broken. What am i missing? Also: Why are there no initialValues?
            setFieldValue(item.id, uploadedFiles);
          } else {
            setFieldValue(item.id, uploadedFiles[0]);
          }

          // Reset input.
          event.target.value = null;
        }}
      />
      {item.fileExtensions && (
        <span className="form-legend form-legend--filefield">
          {language === "en"
            ? "Allowed file formats:"
            : "Erlaubte Dateiformate:"}
          {item.fileExtensions}
        </span>
      )}
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
      {files && files.length > 0 && (
        <>
          {files.map((file, index) => (
            <div key={index} className="d-flex align-center">
              <span style={{ marginRight: "5px" }}>{file.filename}</span>
              <button
                onClick={() => removeFile(file.file_id)}
                type="button"
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>
          ))}
        </>
      )}
      <ErrorMessage component="span" name={item.id} />
    </div>
  );
};

FileField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.bool,
    states: PropTypes.array,
    fileExtensions: PropTypes.string,
    max_filesize: PropTypes.string,
    multiple: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }),
  token: PropTypes.string,
  language: PropTypes.oneOf(["de", "en"]),
};

export default FileField;
