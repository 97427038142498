import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ComponentClickSuggestionLink = (props) => (
  <>
    {props.link.url.routed ? (
      <Link className={props.className} to={props.link.url.path}>
        {props.link.title}
      </Link>
    ) : (
      <a
        className={props.className}
        target={props.link.url.path.startsWith("/intern") ? "_self" : "_blank"}
        rel="noopener noreferrer"
        href={props.link.url.path}
      >
        {props.link.title}
      </a>
    )}
  </>
);

ComponentClickSuggestionLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.object.isRequired,
};

export default ComponentClickSuggestionLink;
