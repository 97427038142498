import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import classNames from "classnames";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Event from "./event/event";
import Person from "./person/person";
import SoundCitySubmissionsPage from "./intern/sound-city/submissions";
import LoginForm from "./intern/login-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import WebformPage from "./intern/sound-city/webform-page";

import footerConfigQuery from "footer-config-query.graphql";
import PageFooter from "./general-components/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import UserStatusBlock from "./intern/user-status-block";
import { authenticationLoginAction } from "./intern/redux-authentication";
import { tokenExpired } from "./intern/util";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    // Clear login status if jwt is expired.
    if (
      this.props.reduxStore.auth.userLoginData.access_token &&
      this.props.reduxStore.auth.userLoginData.jwt.exp <
        Math.round(new Date().getTime() / 1000)
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let type = "unknown";

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      type = "win-phone";
    }

    if (/android/i.test(userAgent)) {
      type = "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      type = "ios";
    }
    document.body.classList.add(type);
  }

  componentDidUpdate(prevProps) {
    // Clear login status if jwt is expired.
    if (
      this.props.reduxStore.auth.userLoginData.access_token &&
      tokenExpired(
        this.props.reduxStore.auth.userLoginData.access_token,
        this.props.reduxStore.auth.userLoginData.jwt.exp
      )
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }
  }

  render() {
    return (
      <div
        className={classNames({
          "main-content": true,
          "jugend-musiziert":
            this.props.reduxStore.appStore.currentNodeId === "1454",
          "kampagne-2024":
            this.props.reduxStore.appStore.currentNodeId === "1667",
        })}
      >
        <header id="site-header">
          <ErrorBoundary>
            <SimpleHeader
              location={this.props.location}
              footerConfig={this.props.footerConfig.configPagesById}
            />
          </ErrorBoundary>
        </header>

        <main className="main-page-content">
          {this.props.reduxStore.auth.userLoginData.logout_token && (
            <UserStatusBlock />
          )}

          <Switch>
            <Route exact path="/intern/login" component={LoginForm} />
            <Route
              exact
              path="/intern/forgot-password"
              component={ForgotPasswordForm}
            />
            <Route
              exact
              path="/intern/reset-pass/temp"
              component={ResetPasswordWithTempPasswordForm}
            />
            <Route
              exact
              path="/intern/sound-city/eingaben"
              component={SoundCitySubmissionsPage}
            />
            <Route
              exact
              path="/intern/webform/:webformId/:submissionId?"
              component={WebformPage}
            />
            <Route exact path="/intern/:alias" component={Landingpage} />
            <Route exact path="/page/:alias" component={Landingpage} />
            <Route exact path="/projekt/:alias" component={Landingpage} />
            <Route exact path="/schule/:alias" component={Landingpage} />
            <Route exact path="/news/:alias" component={Landingpage} />
            <Route exact path="/veranstaltung/:alias" component={Event} />
            <Route exact path="/person/:alias" component={Person} />
            <Route exact path="/" component={Landingpage} />
          </Switch>
        </main>

        <footer id="pageFooter">
          <ErrorBoundary>
            <PageFooter
              footerConfig={this.props.footerConfig.configPagesById}
              menu={this.props.menuQuery.menuByName}
              currentNodeId={this.props.reduxStore.appStore.currentNodeId}
            />
          </ErrorBoundary>
        </footer>
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  menuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default compose(
  graphql(footerConfigQuery, {
    name: "footerConfig",
  }),
  graphql(menuQuery, {
    options: {
      variables: { name: "footer" },
    },
    name: "menuQuery",
  })
)(withRouter(connect(mapStateToProps)(App)));
