import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SocialMedia from "./social-media";
import { animateScroll as scroll } from "react-scroll";

import LoadingIndicator from "./loading-indicator";
import FooterSitemap from "./footer-sitemap";
import ExternalInternalLink from "./link";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite,
  microSiteSettings: reduxStore.appStore.microSiteSettings,
});

/**
 * @todo Add title attribute to links.
 */
class PageFooter extends Component {
  render() {
    if (this.props.footerConfig && this.props.menu) {
      return (
        <div className="container">
          {this.props.footerConfig && this.props.menu && (
            <>
              {this.props.footerConfig.fieldFooterSitemapZeigen && (
                <FooterSitemap
                  menu={this.props.microSite ? this.props.microSiteSettings.mainMenu : "main"}
                  currentNodeId={this.props.currentNodeId}
                />
              )}
              <div className="row">
                <div className="col-16 col-md-3">
                  <div
                    className="up-to-top"
                    onClick={() => scroll.scrollToTop()}
                  >
                    Up to top
                  </div>
                </div>

                <div className="col-16 col-md-13">
                  <div className="footer">
                    <div className="row">
                      <div className="col-16">
                        <div className="row">
                          <div className="col-16 col-lg-7 col-md-6">
                            <nav className="footer-menu">
                              <ul>
                                {this.props.menu.links.map((item, index) => (
                                  <li key={index}>
                                    <ExternalInternalLink link={item} />
                                  </li>
                                ))}
                              </ul>
                            </nav>
                          </div>
                          <div className="col-16 col-md-9">
                            <nav className="partner-menu">
                              <ul>
                                {this.props.footerConfig.fieldPartner.map(
                                  (item, index) => (
                                    <li key={index}>
                                      <a target="_blank" href={item.url.path}>
                                        {/*<ArrowDownRight32 />*/}
                                        {item.title}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <SocialMedia content={this.props.footerConfig} />

                  <div>
                    <p className="copyright">
                      {this.props.footerConfig.fieldCopyright}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  currentNodeId: PropTypes.string.isRequired,
  footerConfig: PropTypes.object,
  menu: PropTypes.object,
};

export default connect(mapStateToProps)(PageFooter);
