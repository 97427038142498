import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphAkkordionText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-akkordion-text": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-8 offset-lg-4">
              <Accordion>
                {this.props.content.fieldAbschnitte.map((item, index) => (
                  <ErrorBoundary key={index}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {item.entity.fieldTitel}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: item.entity.fieldText.value,
                          }}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  </ErrorBoundary>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphAkkordionText.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphAkkordionText;
